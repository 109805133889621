<script lang="ts" setup>
const props = defineProps({
  maxSeatsDelta: {
    type: Number,
    required: true,
  },
  endDate: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['remove'])

const { isInteger, isNumberRange } = useValidation()

const isShown = defineModel<boolean>()

watch(isShown, isShown => {
  if (!isShown) {
    // Reset value when dialog loses focus
    seatsToRemove.value = 0
  }
})

const isActionAllowed = ref(false)

const isValidInput = (input: unknown) => {
  return isInteger(input) && isNumberRange(input, 1, props.maxSeatsDelta)
}

const seatsToRemove = ref(0)
</script>

<template>
  <v-dialog v-model="isShown" max-width="632">
    <v-card data-cy="removeSeatsDialog">
      <v-card-title class="text-h5">
        {{ $t('dialog.business.remove_seats.title') }}
      </v-card-title>
      <v-card-text>
        <div class="mb-6">
          {{ $t('dialog.business.remove_seats.text', { date: endDate }) }}
        </div>
        <v-row align="center">
          <v-col cols="4">
            <v-form
              @submit.prevent
              @update:model-value="
                isValid => {
                  isActionAllowed = isValid
                }
              "
            >
              <v-text-field
                v-model.number="seatsToRemove"
                data-cy="seats_input"
                type="number"
                inputmode="numeric"
                hide-details="auto"
                variant="filled"
                :min="1"
                :max="maxSeatsDelta"
                :rules="[isValidInput]"
              />
            </v-form>
          </v-col>
          <v-col cols="8">
            {{ $t('global.seats') }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon>custom:info</v-icon>
          <span class="font-weight-bold ml-4">
            {{ $t('dialog.business.remove_seats.info') }}
          </span>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="info" variant="outlined" @click="isShown = false">
          {{ $t('global.go_back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="error"
          data-cy="remove_seats_button"
          :disabled="!isActionAllowed"
          variant="elevated"
          @click="emit('remove', seatsToRemove)"
        >
          {{ $t('business.plan.action.remove_seats', seatsToRemove, { count: seatsToRemove }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
